import { Star, StarSolid } from "iconoir-react";
import { cn } from "utils/utils";
export const Rating = ({
  percent,
  scale = 5,
  className,
  isRounding = false,
}: {
  percent?: number;
  scale?: number;
  className?: string;
  isRounding?: boolean;
}) => {
  const calculatedRank = percent
    ? Number(((percent / 100) * scale).toFixed(2))
    : 0;

  const adjustedRank = isRounding
    ? Math.round(calculatedRank * 2) / 2
    : calculatedRank;

  const fullStarsCount = Math.floor(adjustedRank);
  const hasHalfStar = isRounding && adjustedRank % 1 !== 0;
  const fractionalPart = !isRounding ? adjustedRank % 1 : 0;
  const emptyStarsCount =
    scale -
    fullStarsCount -
    (hasHalfStar ? 1 : 0) -
    (fractionalPart > 0 ? 1 : 0);

  return (
    <div className={cn(className, "text-primary relative flex h-full w-fit")}>
      {Array.from({ length: fullStarsCount }, (_, i) => (
        <StarSolid key={i} className="shrink-0 size-5" aria-hidden="true" />
      ))}
      {hasHalfStar && (
        <div className="relative">
          <Star className="size-5 shrink-0" aria-hidden="true" />
          <StarSolid
            className="absolute right-0 top-0 z-20 size-5 shrink-0"
            aria-hidden="true"
            style={{
              clipPath: `polygon(0 0, 50% 0, 50% 100%, 0% 100%)`,
            }}
          />
        </div>
      )}
      {!isRounding && fractionalPart > 0 && (
        <div className="relative">
          <Star className="size-5 shrink-0" aria-hidden="true" />
          <StarSolid
            className="absolute right-0 top-0 z-20 size-5 shrink-0"
            aria-hidden="true"
            style={{
              clipPath: `polygon(0 0, ${fractionalPart * 100}% 0, ${fractionalPart * 100}% 100%, 0% 100%)`,
            }}
          />
        </div>
      )}
      {Array.from({ length: emptyStarsCount }, (_, i) => (
        <Star
          className="size-5 shrink-0"
          aria-hidden="true"
          key={
            i +
            fullStarsCount +
            (hasHalfStar ? 1 : 0) +
            (fractionalPart > 0 ? 1 : 0)
          }
        />
      ))}
    </div>
  );
};
